import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { JSON_FILE_PATH, HTML_BASE_URL, UNIVERSAL_STYLE_CSS_FILE_PATH } from '../constants';
import { Spinner } from './spinner';

export const SingleBlogPage = () => {
	const { blogId } = useParams();
	const [blogDetails, setBlogDetails] = useState({});
	const [blogContent, setBlogContent] = useState({});
	useEffect(() => {
		fetch(JSON_FILE_PATH)
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				if (data) {
					const blog_detail = data.blogs[blogId];
					setBlogDetails(blog_detail);
					document.title = blog_detail.title;
					const fullPath = HTML_BASE_URL + blog_detail.content_url;
					fetch(fullPath)
						.then((response) => {
							return response.text();
						})
						.then((html) => {
							const modifiedHtml = html.replace(
								/<img\s+[^>]*src="([^"]+)"[^>]*>/g,
								(match, relativePath) => {
									return match.replace(/src="([^"]+)"/, `src="${HTML_BASE_URL}$1"`);
								}
							);
							// Now, fetch the external CSS file to inject its styles dynamically
							fetch(UNIVERSAL_STYLE_CSS_FILE_PATH)
								.then((cssResponse) => cssResponse.text())
								.then((cssText) => {
									// Inject the CSS into the HTML content
									const styleTag = `<style>${cssText}</style>`;

									// Append the style to the HTML content
									const fullHtmlContent = styleTag + modifiedHtml;

									setBlogContent(fullHtmlContent); // Set the final content with styles
								});
						})
						.catch((error) => console.error('Error loading blog:', error));
				} else {
					console.log('ERROR', data);
					throw new Error('Invalid data format');
				}
			});
	}, []);
	return (
		<div>
			{blogContent ? (
				<div className="lg:w-[1007px] mx-auto w-full my-20">
					<h1 className="font-bold text-3xl text-center p-8">{blogDetails.title}</h1>
					<img className="rounded-lg" src={HTML_BASE_URL + blogDetails.display_image} />
					<div>
						<p className="font-bold text-lg text-blue-700 px-4 pt-4">
							<a href={'https://www.instagram.com/trinc_academy'} target="_blank">
								Trinc Academy
							</a>
						</p>
						<p className="font-bold text-xs px-4 pb-4">{blogDetails.date}</p>
					</div>
					<div className="blog-content" dangerouslySetInnerHTML={{ __html: blogContent }} />
				</div>
			) : (
				<Spinner className="mt-[20vh] mx-auto" />
			)}
		</div>
	);
};

export const BlogCard = ({ index, id, title, date, summary, tags, displayImage }) => {
	const blogUrl = `/blog/${id}`;

	return (
		<div className="max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl mx-auto border border-gray-200 rounded-lg m-4 hover:shadow-lg bg-white">
			<Link to={blogUrl} className="block">
				<img
					src={HTML_BASE_URL + displayImage}
					alt="Display"
					className="w-full h-[200px] object-cover rounded-lg"
				/>
				<div className="p-4">
					<h2 className="text-xl font-semibold mb-2 text-gray-900 mt-4">{title}</h2>
					<p className="text-gray-600 mb-2">{summary.slice(0, 100)}...</p>

					<p className="text-sm text-gray-500 mb-3">Tags: {tags}</p>
					<p className="text-sm text-gray-500">
						<span>Trinc Academy</span> | <span>{date}</span>
					</p>

					<span className="text-blue-600 hover:underline">
						Read More ...<superscript>[{index}]</superscript>
					</span>
				</div>
			</Link>
		</div>
	);
};

export const BlogList = () => {
	const [blogsList, setBlogsList] = useState(null);

	useEffect(() => {
		fetch(JSON_FILE_PATH)
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				if (data && data.blogs) {
					const blogsArray = Object.keys(data.blogs).map((key) => ({
						id: key,
						...data.blogs[key],
					}));
					setBlogsList(blogsArray);
				} else {
					console.error('Invalid data format:', data);
					throw new Error('Invalid data format');
				}
			})
			.catch((error) => {
				console.error('Error fetching data:', error);
			});
	}, []);

	return (
		<div className="min-h-screen lg:w-[1000px] mx-auto py-8 px-4 mt-16">
			<h1 className="text-3xl font-bold text-center mb-8">Blog Posts</h1>
			{blogsList ? (
				blogsList.length > 0 ? (
					<div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-6">
						{blogsList.map((post, index) => (
							<BlogCard
								index={index + 1}
								key={post.id}
								id={post.id}
								title={post.title}
								date={post.date}
								summary={post.summary}
								tags={post.tags.join(', ')}
								displayImage={post.display_image}
							/>
						))}
					</div>
				) : (
					<p className="text-center text-gray-500">No blog posts available.</p>
				)
			) : (
				<Spinner className="mt-[20vh] mx-auto" />
			)}
		</div>
	);
};
